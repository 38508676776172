module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"name","short_name":"name","start_url":"/","background_color":"#94573A","theme_color":"#94573A","display":"minimal-ui","icon":"src/assets/images/logo-ccl.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3fd72f0fa3c2f19f85e5c3bc9636cb29"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
